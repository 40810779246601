<template>
  <div>
    <rotate-text>Get in touch</rotate-text>
    <div class="row justify-content-end">
      <div class="col-md-10">
        <div class="contact-intro">
          <div class="row">
            <div class="col-lg-6 col-md-9">
              <h2 class="contact-header" data-aos="fade-up">Talk to me.</h2>
              <p class="contact-text" data-aos="fade-up" data-aos-delay="200">
                Get in touch and say hello. Whether you have a work enquiry or
                simply want to find out more, let’s talk.
              </p>
            </div>
          </div>
          <form id="contact-form" @submit.prevent="send" data-aos="fade-up" data-aos-delay="400">
            <div class="row">
              <div class="col-lg-6 col-md-9 order-lg-first order-last">
                <!-- form message -->
                <p class="form-message"></p>

                <!-- input field for NAME -->
                <input
                  class="input_field required"
                  type="text"
                  name="name"
                  v-model="formdata.name"
                  placeholder="Your name"
                  required
                />
                <!-- input field for EMAIL -->
                <input
                  class="input_field required"
                  type="email"
                  name="email"
                  v-model="formdata.email"
                  placeholder="Your email"
                  required
                />
                <!-- input field for EMAIL -->
                <input
                  class="input_field required"
                  type="text"
                  name="subject"
                  v-model="formdata.subject"
                  placeholder="Subject"
                  required
                />
                <!-- input field for MESSAGE -->
                <textarea
                  class="input_field required"
                  name="message"
                  v-model="formdata.message"
                  placeholder="Tell me something."
                  required
                ></textarea>
                <!-- button for MESSAGE-SEND -->
                <button class="form-btn" :disabled="status" type="submit">Send Message</button>
              </div>
              <div class="col-lg-6 col-md-12 order-lg-last order-first">
                <div class="contact-info-box">
                  <h5>Email</h5>
                  <p>{{ this.$config.email }}</p>
                </div>
                <!-- /contact-info-box -->
                <div class="contact-info-box">
                  <h5>Phone</h5>
                  <p>{{ this.$config.phone_number }}</p>
                </div>
                <!-- /contact-info-box -->
                <div class="contact-info-box">
                  <h5>Address</h5>
                  <p>{{ this.$config.address }}</p>
                </div>
                <!-- /contact-info-box -->
              </div>
            </div>
          </form>
          <social-icons />
        </div>
        <!-- /contact-wrapper -->

        <next-page path="/" title="Home" content="Go Back Home"></next-page>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NProgress from "nprogress";
import SocialIcons from "../components/SocialIcons";

export default {
  name: "Contact",
  components: {
    SocialIcons,
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: "Get in touch with Benjamin Iduwe, just send me a message",
        },
        {
          name: "og:title",
          content: "Get in touch with Benjamin Iduwe, just send me a message",
        },
      ],
    };
  },
  data() {
    return {
      status: false,
      formdata: {
        name: "",
        subject: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    send() {
      NProgress.start();
      this.status = true;
      axios
        .post(`${this.$config.api.url}/messages/store`, this.formdata)
        .then((response) => {
          NProgress.done();
          this.status = false;

          if (response.data.status) {
            this.$toast.success(response.data.message);
            this.formdata = {};
          } else {
            this.$toast.warning(response.data.message);
          }
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          this.status = false;
          NProgress.done();
          if (error.response) {
            this.$toast.danger("Network error");
          }
          for (error in errors) {
            const message = errors[error][0];
            this.$toast.warning(message);
          }
        });
    },
  },
  computed: {
    title() {
      return `Contact Me - ${this.$config.name}`;
    },
  },
};
</script>
