<template>
  <div class="row">
    <div class="col-md-12">
      <div class="info-section socials-buttons">
        <h5 class="section-title mb-0" data-aos="fade-up">
          You can find me on
        </h5>
        <div class="social-item email" data-aos="fade-up" data-aos-delay="100">
          <a target="_blank" :href="`mailto:${this.$config.email}`">
            <span class="ti-email"></span>
            <span>email</span>
          </a>
        </div>
        <div
          class="social-item twitter"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <a
            target="_blank"
            :href="`https://twitter.com/${this.$config.socials.twitter}`"
          >
            <span class="ti-twitter"></span>
            <span>twitter</span>
          </a>
        </div>
        <div
          class="social-item facebook"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <a
            target="_blank"
            :href="`https://facebook.com/${this.$config.socials.facebook}`"
          >
            <span class="ti-facebook"></span>
            <span>facebook</span>
          </a>
        </div>
        <div
          class="social-item instagram"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <a
            target="_blank"
            :href="`http://github.com/${this.$config.socials.github}`"
          >
            <span class="ti-github"></span>
            <span>Github</span>
          </a>
        </div>
        <div
          class="social-item linkedin"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <a
            target="_blank"
            :href="`https://linkedin.com/in/${this.$config.socials.linkedin}`"
          >
            <span class="ti-linkedin"></span>
            <span>LinkedIn</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialIcons",
};
</script>
